import React, { useState } from 'react';
import axios from 'axios';

interface SpotifyTrack {
    id: string;
    name: string;
    album: {
        images: { url: string }[];
    };
    artists: { name: string }[];
}

interface SpotifySearchBarProps {
    onTrackSelect?: (track: SpotifyTrack) => void;
    customClass?: string;
    placeHolder?: string;
}

export function SpotifySearchBar({ onTrackSelect, customClass, placeHolder = 'Find your track...' }: SpotifySearchBarProps) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<SpotifyTrack[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);

    // Function to call the backend API
    const searchSpotify = async (query: string) => {
        if (query.trim().length < 2) {
            setSearchResults([]);
            setShowResults(false);
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.get('/api/spotify-search', {
                params: {
                    query: query,
                    limit: 25
                }
            });

            if (response.data.tracks && response.data.tracks.items) {
                setSearchResults(response.data.tracks.items);
                setShowResults(true);
            }
        } catch (err) {
            console.error('Error searching Spotify:', err);
        } finally {
            setIsLoading(false);
        }
    };

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchQuery(query);

        setTimeout(() => {
            searchSpotify(query);
        }, 500);
    };

    // Handle track selection
    const handleTrackSelect = (track: SpotifyTrack) => {
        setSearchQuery(track.name + ' - ' + track.artists[0].name);
        setShowResults(false);

        // Store track data in localStorage
        localStorage.setItem('selectedTrack', JSON.stringify(track));

        // If there's an external handler, call it
        if (onTrackSelect) {
            onTrackSelect(track);
        } else {
            // Default behavior - navigate to selection page
            window.location.href = '/test-promotion-more';
        }
    };

    // Handle closing results when clicking outside
    const handleBlur = () => {
        // Small delay to allow click event on results to trigger first
        setTimeout(() => {
            setShowResults(false);
        }, 200);
    };

    return (
        <div className="relative">
            <div className="text-md w-full">
                <div className="relative z-[900]">
                    <input
                        className="w-full pr-14 text-md text-white placeholder-white bg-black bg-opacity-50 border border-white rounded-full outline-none transition-shadow duration-300 ease-in-out"
                        placeholder={placeHolder}
                        id="spotify-search"
                        type="text"
                        value={searchQuery}
                        onChange={handleInputChange}
                        onFocus={() => searchQuery.length >= 2 && setShowResults(true)}
                        onBlur={handleBlur}
                    />

                </div>


                {showResults && searchResults.length > 0 && (
                    <div className="ios-scroll rounded-boost ios-scroll z-[9999] max-h-[18em] w-full translate-x-0 cursor-default overflow-hidden overflow-y-scroll text-xl text-white absolute mt-2 bg-black">
                        {isLoading ? (
                            <div className="p-4 text-center">Loading...</div>
                        ) : (
                            searchResults.map((track) => (
                                <div
                                    key={track.id}
                                    className="flex items-center p-2 hover:bg-gray-800 cursor-pointer"
                                    onMouseDown={() => handleTrackSelect(track)}
                                >
                                    {track.album.images.length > 0 && (
                                        <img
                                            src={track.album.images[track.album.images.length - 1].url}
                                            alt={track.name}
                                            className="w-30 h-30 object-cover rounded-md mr-4"
                                        />
                                    )}
                                    <div>
                                        <div className="text-sm font-bold">{track.name}</div>
                                        <div className="text-xs text-gray-400">
                                            {track.artists.map((artist) => artist.name).join(', ')}
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SpotifySearchBar;