import React, { useState } from 'react';
import { SignalWifi1BarIcon } from '@common/icons/material/SignalWifi1Bar';
import { SignalWifi2BarIcon } from '@common/icons/material/SignalWifi2Bar';
import { SignalWifi4BarIcon } from '@common/icons/material/SignalWifi4Bar';
import { CheckIcon } from '@heroicons/react/20/solid';
import trustpilot from "/common/resources/client/footer-pages/components/TP.png"


// Import the tiers data
import { tiers } from './test-promotion-select-package';

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
}

export function TestPromotionSliderPackages() {
    const [sliderValue, setSliderValue] = useState(1); // Default to middle package
    const [selectedPackage, setSelectedPackage] = useState(tiers[1]); // Default to middle package

    // Handle slider change
    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value);
        setSliderValue(value);
        setSelectedPackage(tiers[value]);
    };

    return (
        <div className="mx-auto max-w-5xl px-6 lg:px-8">
            <div className="relative z-0">


                {/* Package card with slider */}
                <div className="mt-16 mx-auto max-w-2xl bg-black rounded-xl p-8 border border-danger shadow-xl">
                    {/* Streams indicator */}


                    {/* Slider control */}
                    <div className="mb-8">
                        <input
                            type="range"
                            min="0"
                            max="2"
                            step="1"
                            value={sliderValue}
                            onChange={handleSliderChange}
                            className="w-full h-2 bg-gray-700 rounded-lg appearance-none cursor-pointer accent-danger"
                        />

                        <div className="flex justify-between mt-2">
                            <div className="flex flex-col items-center">
                                <SignalWifi1BarIcon className={`h-8 w-8 ${sliderValue === 0 ? 'text-danger' : 'text-gray-500'}`} />
                                <span className={`text-xs mt-1 ${sliderValue === 0 ? 'text-white' : 'text-gray-500'}`}>Local Star</span>
                            </div>
                            <div className="flex flex-col items-center">
                                <SignalWifi2BarIcon className={`h-8 w-8 ${sliderValue === 1 ? 'text-danger' : 'text-gray-500'}`} />
                                <span className={`text-xs mt-1 ${sliderValue === 1 ? 'text-white' : 'text-gray-500'}`}>Rising Artist</span>
                            </div>
                            <div className="flex flex-col items-center">
                                <SignalWifi4BarIcon className={`h-8 w-8 ${sliderValue === 2 ? 'text-danger' : 'text-gray-500'}`} />
                                <span className={`text-xs mt-1 ${sliderValue === 2 ? 'text-white' : 'text-gray-500'}`}>Superstar</span>
                            </div>
                        </div>
                    </div>

                    {/* Pitched playlists */}
                    <div className="text-center mb-8">
                        <div className="text-danger font-bold text-lg">
                            {selectedPackage.name === 'Local Star' ? '50+' :
                                selectedPackage.name === 'Rising Artist' ? '75+' : '100+'} Pitched Playlists
                        </div>
                        <div className="text-white text-lg">(6 weeks placement)</div>
                    </div>

                    <hr className="border-white/10 my-6" />

                    {/* Package details */}
                    <div className="flex items-center justify-between mb-6">
                        <h3 className="text-lg">
                            Average Organic streams <span className="text-xl font-bold text-danger block">{selectedPackage.stream}</span>
                        </h3>
                        <div>
                            <span className="text-xl text-gray-500 line-through block">{selectedPackage.actualPrice}</span>
                            <span className="text-xl font-bold text-danger">{selectedPackage.price}</span>
                        </div>
                    </div>


                </div>

                {/* Help section */}
                <div className="mt-16 text-center">
                    <div className="flex flex-col items-center" style={{ marginLeft: "10px" }}>
                        <a href="https://uk.trustpilot.com/review/beatsora.com" target="_blank" rel="noopener noreferrer">
                            <img src={trustpilot} className="mt-10 lg:mt-0 w-[200px]" alt="Trustpilot Reviews" />
                        </a>
                    </div>
                    <p className='text-center mx-4 font-semibold'>
                        Have any questions or need custom promotion?
                    </p>
                    <p className='text-center mx-4 pt-6 pb-12'>
                        Schedule a free 15 minute discovery call
                    </p>
                    <a
                        href="https://calendly.com/iamoutzpoken/30min"
                        target="_blank"
                        className={classNames(
                            'bg-danger shadow-sm hover:bg-dangerlighter',
                            'inline-block rounded-md py-3 px-8 text-md font-semibold text-white transition-all duration-200'
                        )}
                    >
                        Book a free meeting
                    </a>
                </div>
            </div>
        </div>
    );
}

export default TestPromotionSliderPackages;